import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { ProductService } from './product.service';
import { LocationService } from '../locations/location.service';
import { Product } from './product/product';
import { Place } from '../locations/place';
import { Cart } from '../cart/cart';
// import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AuthService } from  '../auth/auth.service';
// import { Observable } from 'rxjs';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, AfterContentChecked {

  // auth$: Observable<any>;
  // _isLoggedIn: boolean;
  _isAdmin: boolean;

  products: Product[];
  places: Place[];
  uniquePlaces = [];

  cart: Cart[] = [];

  itemsCount = 0;
  itemsTotal = 0;

  constructor(
    private productService: ProductService,
    private locationService: LocationService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.productService.getProducts().subscribe(data => {
      this.products = data.map(e => {
        return {
          id: e.payload.doc['id'],
          ...e.payload.doc.data() as Product
        };
      })
    });
    this.locationService.getLocations().subscribe(data => {
      data.map(e => {
        return {
          id: e.payload.doc['id'],
          ...e.payload.doc.data() as Place
        };
      }).forEach(d => {
        if(d.active) {
          this.uniquePlaces.push(d.city);
          const tmpSet = new Set(this.uniquePlaces);
          this.uniquePlaces = [...tmpSet];
        }
      })
    });

    this.authService.user$.subscribe(data => {
      this._isAdmin = data.admin;
    });
  }

  /*
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.products, event.previousIndex, event.currentIndex);
  }
  */

  ngAfterContentChecked() {
    this.cartTotal();
  }

  /*
  create(product: Product){
      this.productService.createProduct(product);
  }

  update(product: Product) {
    this.productService.updateProduct(product);
  }
  */

  delete(name: string, id: string) {
    if(confirm("Are you sure to delete " + name)) {
      this.productService.deleteProduct(id);
    }
  }

  setVisibility(product: string, published: boolean) {
    this.productService.setProductVisibility(product, published);
  }

  addToBasket(id: string) {
    sessionStorage.setItem(id, String(Number(this.getBasketCount(id)) + 1));
    this.cartTotal();
  }

  removeFromBasket(id: string) {
   this.getBasketCount(id) === '1'
   ? sessionStorage.removeItem(id)
   : sessionStorage.setItem(id, String(Number(this.getBasketCount(id)) - 1));
   this.cartTotal();
  }

  removeItem(id: string) {
    sessionStorage.removeItem(id);
    this.cartTotal();
  }

  getBasketCount(id: string) {
    return sessionStorage.getItem(id);
  }

  clearCart() {
    for (let i = 0; i < sessionStorage.length; i++) {
      let key = sessionStorage.key(i);
      if(key != 'ycSdkClientIdentifier') {
        sessionStorage.removeItem(key);
      }
    }
    this.cartTotal();
  }

  cartOrders() {
    return sessionStorage.length > 1 ? true : false;
  }

  cartTotal() {
    let total = 0;
    let count = 0;
    for (let i = 0; i < sessionStorage.length; i++) {
      let key = sessionStorage.key(i);
      if(key != 'ycSdkClientIdentifier') {
        let value = sessionStorage.getItem(key);
        let price = this.products?.find(data => data.id === key).price;
        total = total + Number(value) * price;
        count = count + Number(value);
      }
    }
    this.itemsCount = count;
    this.itemsTotal = total;
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Order } from './order';
import { User } from './../user/user';
import { Product } from '../products/product/product';
import { firestore } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  order: Order;
  // user: User;

  constructor(
    private firestore: AngularFirestore
  ) { }

  getOrders() {
    return this.firestore.collection('orders', ref => {
          let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          query = query.orderBy('orderTime', 'desc');
          return query;
        }).snapshotChanges()
    // return this.firestore.collection('orders').snapshotChanges();
  }

  getOrder(id: string) {
    return this.firestore.collection('orders').doc(id).valueChanges();
  }

  createOrder(orderID: string, itemCount: number, orderTotal: number, user: User, products: Product, delivery: any, paid: boolean, paymentDetails: Object) {
    let order = new Order;
    order.orderID = orderID;
    order.itemCount = itemCount;
    order.orderTotal = orderTotal;
    order.orderTime = firestore.FieldValue.serverTimestamp();
    order.user = user;
    order.products = products;
    order.delivery = delivery;
    order.paid = paid;
    order.paymentDetails = paymentDetails || null;;
    order.delivered = false;
    order.packed = false;

    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection('orders')
        .doc(orderID)
        .set({...order})
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  updateOrder(order: Order) {
    delete order.id;
    this.firestore.doc('orders/' + order.id).update(order);
  }

  deleteOrder(orderID: string) {
    this.firestore.doc('orders/' + orderID).delete();
  }

  mark(orderID: string, type: string, value: boolean) {
    switch (type) {
      case 'paid':
        this.firestore.collection('orders').doc(orderID).update({ paid: value })
        break;
      case 'packed':
        this.firestore.collection('orders').doc(orderID).update({ packed: value })
        break;
      case 'delivered':
        this.firestore.collection('orders').doc(orderID).update({ delivered: value })
        break;
      default:
        break;
    }
  }

}

<form #f="ngForm" name="locationFrom" (ngSubmit)="saveLocation()">

<mat-card style="max-width: 400px; margin: 0 auto 0 auto">

  <mat-card-title>
    Create new Location
  </mat-card-title>

<mat-card-content *ngIf="!submitted" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="8px">

  <div fxLayout.gt-xs="row wrap" fxLayout.lt-sm="column" fxLayoutGap="8px">
    <mat-form-field fxFlex appearance="fill">
      <mat-label>Location Name</mat-label>
      <input matInput autocomplete="off" type="text" id="name" name="name" required [(ngModel)]="place.name" placeholder="Caltex Garage">
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Street number and name</mat-label>
      <input matInput autocomplete="off" type="text" id="address" name="address" required [(ngModel)]="place.address" placeholder="4 Main Street">
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Suburb</mat-label>
      <input matInput autocomplete="off" type="text" id="suburb" name="suburb" required [(ngModel)]="place.suburb" placeholder="Vincent">
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>City</mat-label>
      <input matInput autocomplete="off" type="text" id="city" name="city" required [(ngModel)]="place.city" placeholder="East London">
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Google Maps URL / Link</mat-label>
      <input matInput autocomplete="off" type="text" id="mapsUrl" name="mapsUrl" required [(ngModel)]="place.mapsUrl" placeholder="Google Maps URL">
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Minimum Order Amount</mat-label>
      <span matPrefix>ZAR &nbsp;</span>
      <input matInput autocomplete="off" type="number" id="min_order" name="min_order" required [(ngModel)]="place.min_amount" placeholder="140">
      <span matSuffix>.00</span>
    </mat-form-field>

    <mat-form-field fxFlex appearance="fill">
      <mat-label>Notes</mat-label>
      <textarea matInput autocomplete="off" type="text" id="notes" name="notes" [(ngModel)]="place.notes" placeholder="Notes"></textarea>
    </mat-form-field>

  </div>

</mat-card-content>

<mat-card-actions fxLayout="column" fxLayoutAlign="center center">
  <button mat-flat-button type="submit" [disabled]="!f.valid || submitting" color="accent">Save</button>
</mat-card-actions>

<div *ngIf="submitted">
  <h4>Location Created!</h4>
  <button mat-flat-button color="accent" (click)="newLocation()">Create another</button>
</div>

</mat-card>

</form>

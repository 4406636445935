import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from  './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'The Chocolate Shoppe';

  _isLoggedIn: boolean = false;
  _isAdmin: boolean = false;

  icons = ['gift', 'check', 'facebook', 'contact', 'basket', 'packed', 'phone', 'eye', 'plus', 'minus', 'sign-in', 'sign-out', 'google', 'info', 'question', 'store', 'truck', 'register'];

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private authService: AuthService
  ) {

    this.icons.forEach(element => {
      iconRegistry.addSvgIcon(
        element,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${element}.svg`)
      );
    });

    }

    ngOnInit() {
      this.authService.getEmitter().subscribe((res) => {
        this._isLoggedIn = res.isLoggedIn;
        this._isAdmin = res.isAdmin;
      });
    }

    googleLogin() {
      this.authService.loginWithGoogle();
    }

    logout() {
      this.authService.logout();
    }

}

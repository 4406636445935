import { Component, OnInit } from '@angular/core';
import { LocationService } from './location.service';
import { Place } from './place';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  _isAdmin = true;
  places: Place[];
  times: any[];

  constructor(
    private locationService: LocationService
  ) { }

  ngOnInit(): void {
    this.locationService.getLocations().subscribe(data => {
      this.places = data.map(e => {
        return {
          id: e.payload.doc['id'],
          ...e.payload.doc.data() as Place
        };
      })
    });
    this.times = this.timeIntervals();
  }

  timeIntervals() {
    const halfHours = ['00', '30'];
    const times = [];
    for (let i = 7; i < 20; i++) { // starting time == 7am, until 8pm
      for (let j = 0; j < 2; j++) {
        // Using slice() with negative index => You get always (the last) two digit numbers.
        times.push( ('0' + i).slice(-2) + ':' + halfHours[j] );
      }
    }
    return times;
  }

  deleteLocation(id, name){
    if(confirm("Are you sure to delete Location: " + name + "?")) {
      this.locationService.deleteLocation(id);
    }
  }

  setVisibility(placeId: string, active: boolean) {
    this.locationService.setLocationVisibility(placeId, active);
  }

  setDeliveryDate(placeId: string, event: MatDatepickerInputEvent<Date>) {
    this.locationService.updateDeliveryDate(placeId, event.value);
  }

  setDeliveryTime(placeId: string, event) {
    this.locationService.updateDeliveryTime(placeId, event.value);
  }

  save(placeId: string, notes: string, min_amount: number) {
    this.locationService.saveNotes(placeId, notes);
    this.locationService.saveMinAmount(placeId, min_amount)
  }

}

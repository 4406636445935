import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../orders.service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Location } from '@angular/common';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  order: Observable<{}>;
  orderID: string;

  constructor(
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    private firestore: AngularFirestore,
    private location: Location
  ) {
    this.orderID = this.route.snapshot.paramMap.get('id');

    this.order = this.firestore.collection('orders').doc(this.orderID).valueChanges();
  }

  ngOnInit(): void {
    /*
    const id = this.route.snapshot.paramMap.get('id');

    this.order = this.ordersService.getOrder(id).subscribe(doc => {
      // this.order = doc;
      // console.log(this.order);
    });
    */
  }

  deleteOrder(id){
    if(confirm("Are you sure to delete Order #" + id + "?")) {
      this.ordersService.deleteOrder(id);
    }
  }

  mark(orderID: string, type: string, value: boolean) {
    this.ordersService.mark(orderID, type, value);
  }

  back() {
    this.location.back();
  }

}

import { Component, OnInit } from '@angular/core';
import { LocationService } from '../location.service';
import { Place } from '../place';
import { Location } from '@angular/common';

@Component({
  selector: 'app-location-create',
  templateUrl: './location-create.component.html',
  styleUrls: ['./location-create.component.scss']
})
export class LocationCreateComponent implements OnInit {

  place: Place = {
    name: '',
    address: '',
    suburb: '',
    city: '',
    mapsUrl: '',
    notes: '',
    min_amount: 0,
    active: true
  };

  submitted = false;
  submitting = false;

  constructor(
    private locationService: LocationService,
    private location: Location,
  ) { }

  ngOnInit(): void {
  }

  saveLocation() {
    this.submitting = true;
    const data: Place = {
      name: this.place.name,
      address: this.place.address,
      suburb: this.place.suburb,
      city: this.place.city,
      mapsUrl: this.place.mapsUrl,
      notes: this.place.notes,
      min_amount: this.place.min_amount,
      active: this.place.active
    };

    this.locationService.createLocation(data)
      .then(
        response => {
          this.submitted = true;
          this.location.back();
        },
        error => {
          console.log(error);
        });
  }

  newLocation() {
    this.submitted = false;
    this.place = {
      name: '',
      address: '',
      suburb: '',
      city: '',
      mapsUrl: '',
      notes: '',
      min_amount: 0,
      active: true
    };
  }

}

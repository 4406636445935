<form style="padding: 2%" [formGroup]="contactForm" (ngSubmit)="onSubmit()">

  <mat-card style="">
    <mat-card-header>
      <mat-card-title>Contact Us</mat-card-title>
      <!-- <mat-card-subtitle>Please use form</mat-card-subtitle> -->
    </mat-card-header>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
          <mat-label>Name</mat-label>
          <input matInput type="text" placeholder="" formControlName="name">
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
          <mat-label>Phone Number</mat-label>
          <input matInput type="tel" placeholder="" formControlName="tel">
          <mat-icon matSuffix>phone</mat-icon>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
        <mat-label>Email Address</mat-label>
        <input matInput type="email" placeholder="" formControlName="email">
        <mat-icon matSuffix>email</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
        <mat-label>How can we Help?</mat-label>
        <textarea matInput formControlName="comments" rows="4" placeholder=""></textarea>
      </mat-form-field>

    <mat-card-actions fxLayoutAlign="end end">
      <button mat-flat-button color="accent" type="submit" [disabled]="disabledSubmitButton">Send Enquiry</button>
      <!-- <div id="message">test</div> -->
    </mat-card-actions>

    <mat-card-footer>
      <div style="text-align: center; margin-top: 25px; margin-bottom: 25px">
        <div>3 Main Road, Hogsback<br />Eastern Cape, South Africa, 5721</div>
        <div>
          <span>+27 74 721 4885</span><br />
          <a href="mailto:info@thechocolateshoppe.co.za">info@thechocolateshoppe.co.za</a>
        </div>
      </div>
    </mat-card-footer>

  </mat-card>

</form>

import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../products/product.service';
import { Product } from '../products/product/product';
import { Place } from '../locations/place';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { OrdersService } from '../orders/orders.service';
import { LocationService } from '../locations/location.service';
declare var YocoSDK: any;

export interface DialogData {
  message: '';
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, AfterContentChecked {

  orderForm: FormGroup;

  paymentCard = false;
  paymentEFT = false;
  orderSent = false;
  orderComplete = false;

  products: Product[];
  places: Place[];
  place;
  ship = true;
  shipping_amount = 120;
  displayedColumns: string[] = ['product', 'qty', 'price', 'amount'];
  dataSource = [];
  grandTotal = 0;
  totalItems = 0;
  today = new Date();
  // disabledSubmitButton = false;

  responseErrorTitle = '';
  responseErrorSubTitle = '';
  responseErrorContent= '';
  responseErrorButton = '';

  constructor(
    private productService: ProductService,
    private locationService: LocationService,
    private ordersService: OrdersService,
    private fb: FormBuilder,
    private http: HttpClient,
    private _location: Location,
    public router: Router
  ) {
    this.orderForm = fb.group({
      'name': [localStorage.getItem('name'), Validators.required],
      'email': [localStorage.getItem('email'), Validators.compose([Validators.required, Validators.email])],
      'tel': [localStorage.getItem('tel'), Validators.required],
      'location': ['', [Validators.required]],
      'address': [localStorage.getItem('address')],
      'deliveryaddress': [localStorage.getItem('deliveryaddress')],
      'comments': [localStorage.getItem('comments')]
    });
  }

  ngOnInit() {
    this.productService.getProducts().subscribe(data => {
      this.products = data.map(e => {
        return {
          id: e.payload.doc['id'],
          ...e.payload.doc.data() as Product
        };
      })
    });

    this.locationService.getLocations().subscribe(data => {
      this.places = data.map(e => {
        return {
          id: e.payload.doc['id'],
          ...e.payload.doc.data() as Place
        };
      })
    });
    this.orderForm.get('location').valueChanges.subscribe(val => {
      val == 'custom'
      ? this.orderForm.get('deliveryaddress').setValidators(Validators.required)
      : this.orderForm.get('deliveryaddress').setValidators(null)
    });
    this.orderForm.valueChanges.subscribe(val => {
      for(let key of Object.keys(val)) {
        let valName = val[key];
        localStorage.setItem(key, valName);
      }
    })
  }
  ngAfterContentChecked() {
    this.getItems();
  }

  getLocation() {
    return localStorage.getItem('location');
  }

  getItems() {
    this.dataSource = [];
    let total = 0;
    let count = 0;

    for (let i = 0; i < sessionStorage.length; i++) {
      let key = sessionStorage.key(i);
      if (key != 'ycSdkClientIdentifier') {
        let qty = Number(sessionStorage.getItem(key));
        let price = Number(this.products ?.find(x => x.id === key) ?.price);
        let name = this.products ?.find(x => x.id === key) ?.name;

        total = total + qty * price;
        count = count + qty;

        this.dataSource.find(x => x.product === name)
          ? ''
          : this.dataSource.push({
            id: key,
            product: name,
            qty: qty,
            price: price,
            amount: qty * price
          });
      }
    }
    if (this.ship) {
      this.dataSource.push({
        id: 'shipping',
        product: 'Shipping',
        qty: 1,
        price: this.shipping_amount,
        amount: this.shipping_amount
      });
      total = total + this.shipping_amount;
      // count = count + qty;
    }

    this.dataSource = this.dataSource;
    this.grandTotal = total;
    this.totalItems = count;
  }

  addToBasket(id: string) {
    sessionStorage.setItem(id, String(Number(this.getBasketCount(id)) + 1));
    this.getItems();
  }

  getBasketCount(id: string) {
    return sessionStorage.getItem(id);
  }

  removeFromBasket(id: string) {
    this.getBasketCount(id) === '1'
      ? sessionStorage.removeItem(id)
      : sessionStorage.setItem(id, String(Number(this.getBasketCount(id)) - 1));
    this.getItems();
  }

  removeItem(id: string) {
    sessionStorage.removeItem(id);
    this.getItems();
  }

  submitOrder(type: string, data?: Object) {
    this.orderForm.disable();
    this.showOverlay();
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();
    body = body.set('email', this.orderForm.get('email').value);
    body = body.set('name', this.orderForm.get('name').value);
    body = body.set('tel', this.orderForm.get('tel').value);
    body = body.set('comments', this.orderForm.get('comments').value);
    body = body.set('items', JSON.stringify(this.dataSource));

    let url: string;

    /*
    if(type == 'Card' && (this.orderForm.get('deliveryaddress').value != '')) {
      url = 'https://www.thechocolateshoppe.co.za/the_server_files/send_order_card.php';
      body = body.set('deliveryaddress', this.orderForm.get('deliveryaddress').value);
    } else {
    url = 'https://www.thechocolateshoppe.co.za/the_server_files/send_order.php';
      body = (this.place.nextDeliveryDate?.toDate() >= this.today && this.place.deliveryTime)
        ? body.set('valid_date', 'yes')
        : body.set('valid_date', 'no');
        body = body.set('place', JSON.stringify(this.place));
        body = body.set('address', this.orderForm.get('address').value);
    }
    */

    url = 'https://www.thechocolateshoppe.co.za/the_server_files/send_order.php';
    body = body.set('paymentMethod', type);
    body = body.set('deliveryaddress', this.orderForm.get('deliveryaddress').value);

    if(this.place) {
      body = (this.place.nextDeliveryDate?.toDate() >= this.today && this.place.deliveryTime)
        ? body.set('valid_date', 'yes')
        : body.set('valid_date', 'no');
        body = body.set('place', JSON.stringify(this.place));
    }

    body = body.set('address', this.orderForm.get('address').value);

    this.http.post(url, body, { headers: myheader }).subscribe(
      (res: Response) => {
        let delivery;
        delivery = {
          date: this.place?.nextDeliveryDate || '',
          time: this.place?.deliveryTime || '',
          place: (this.place?.name + ', ' + this.place?.suburb) || '',
          address: this.orderForm.get('deliveryaddress').value
        };

        this.saveOrder(res['orderID'], res['itemCount'], res['orderTotal'], this.orderForm.value, this.dataSource, delivery, this.paymentCard, data);
        this.clearCart();
        this.orderSent = true;
        this.isComplete();

        document.getElementById('response_title').innerHTML = 'Thank you!';
        document.getElementById('response_subtitle').innerHTML = 'Your order was processed successfully.';
        document.getElementById('response_content').innerHTML = 'You will receive an email shortly with your order details. Please check your <i>SPAM</i> folder if you do not receive the email in a few minutes.';

        document.getElementById('response_card').style.display = 'block';
        document.getElementById('my-spinner').style.display = 'none';
      }
    );

  }

  saveOrder(orderID, itemCount, orderTotal, person, items, delivery, paid, deliveryDetails) {
    this.ordersService.createOrder(orderID, itemCount, orderTotal, person, items, delivery, paid, deliveryDetails);
  }

  setDelivery(event) {
    this.place = '';
    this.orderForm.get('deliveryaddress').setValue('');

    this.ship = (event.value == 'custom' && this.grandTotal < 10000) ? true : false;
    // this.ship = true;

    const placeId = event.value;
    this.place = this.places.find(x => x.id == placeId);
  }

  payEFT() {
    // this.disabledSubmitButton = true;
    this.showOverlay();
    this.paymentEFT = true;
    this.submitOrder('EFT');
  }

  payYoco() {
    var self = this;

    var yoco = new YocoSDK({
      publicKey: 'pk_live_83e8e683l6jKzNP83de4',
      // publicKey: 'pk_test_2d49062cdBe44oB3ec84',
    });

    yoco.showPopup({
      amountInCents: self.grandTotal * 100, // amount in cents
      currency: 'ZAR',
      name: 'The Chocolate Shop',
      description: "Chocolates",
      callback: function(result) {
        if (result.error) {
          const errorMessage = result.error.message;
          alert("error occured: " + errorMessage);
        } else {
          // alert("card successfully tokenised: " + result.id);
          self.requestPayment(result.id, self.grandTotal * 100);
        }
      }
    })
  }

  requestPayment(token: string, amount: number) {
    // var message = '';
    // this.disabledSubmitButton = true;
    this.showOverlay();
    document.getElementById('my-spinner').style.display = 'block';
    document.getElementById('response_card').style.display = 'none';

    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();
    body = body.set('token', token);
    body = body.set('amount', amount.toString());

    const url = 'https://www.thechocolateshoppe.co.za/the_server_files/get_paid.php';

    this.http.post(url, body, { headers: myheader }).subscribe(
      (res: Response) => {
        console.log(res);
        if (res.status && res.status.toString() == 'successful') {
          this.paymentCard = true;
          this.submitOrder('Card', res['source']);
        } else {
          this.paymentCard = false;
          // alert(res['errorMessage']);
          document.getElementById('response_title').innerHTML = 'Oops!';
          document.getElementById('response_subtitle').innerHTML = res['errorMessage'];
          document.getElementById('response_content').innerHTML = 'There seem to have been an error with processing your card. ' + res['displayMessage'];

          document.getElementById('response_card').style.display = 'block';
          document.getElementById('my-spinner').style.display = 'none';
          // this.disabledSubmitButton = false;
          // this.hideOverlay();
        }
      }
    );
  }

  clearCart() {

    for (let i = 0; i < sessionStorage.length; i++) {
      let key = sessionStorage.key(i);
      if (key != 'ycSdkClientIdentifier') {
        sessionStorage.removeItem(key);
      }
    }
  }

isComplete() {
  this.orderComplete = true;
  return ((this.paymentCard || this.paymentEFT) && this.orderSent) ? true : false;
}

showOverlay() {
  document.getElementById('my-overlay').style.display = 'block';
}

hideOverlay() {
  document.getElementById('my-overlay').style.display = 'none';
  this.orderComplete ? this.router.navigateByUrl('/products') : '';
}

}

import { Component, OnInit } from '@angular/core';
import { OrdersService } from './orders.service';
import { Order } from './order';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  orders$: Order[];

  constructor(
    private ordersService: OrdersService,
    private firestore: AngularFirestore
  ) {

    this.ordersService.getOrders().subscribe(data => {
      this.orders$ = data.map(e => {
        return {
          id: e.payload.doc['id'],
          ...e.payload.doc.data() as Order
        };
      })
    });
    /*
    this.orders$ = this.firestore.collection('orders', ref => {
          let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          query = query.orderBy('orderTime', 'desc');
          return query;
        }).valueChanges()
    */
  }

  ngOnInit() {}

  deleteOrder(id){
    if(confirm("Are you sure to delete Order #" + id + "?")) {
      this.ordersService.deleteOrder(id);
    }
  }

  mark(orderID: string, type: string, value: boolean) {
    this.ordersService.mark(orderID, type, value);
  }

}

<form [formGroup]="productForm" (ngSubmit)="onSubmit()">

<mat-card style="max-width: 400px; margin: 0 auto 0 auto">

<mat-card-title>
  {{ productId ? 'Edit' : 'Create' }} Product
</mat-card-title>

<mat-card-content fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="8px">

  <div fxLayout.gt-xs="row" fxLayout.lt-sm="column" fxLayoutGap="8px">
    <mat-form-field fxFlex appearance="fill">
      <mat-label>Product Name</mat-label>
      <input matInput autocomplete="off" required formControlName="name" placeholder="My awesome chocolate">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Price</mat-label>
      <input matInput type="number" required formControlName="price" placeholder="How much is it?">
    </mat-form-field>
  </div>

  <mat-form-field class="full-width" appearance="fill">
    <mat-label>Notes</mat-label>
    <textarea matInput type="text" formControlName="description" placeholder="I contain nuts, and I'm also vegan!">
    </textarea>
  </mat-form-field>

</mat-card-content>

<input hidden
type="file"
id="imageUpload"
#productPhoto
(change)="onFileSelected($event)"
name="imageUpload"
autocomplete="off"
/>

<input hidden type="text" formControlName="imageUrl" #imageUrl>

<mat-card-content fxLayoutAlign="center center">

  <img (click)="productPhoto.click()" style="width: 100%;"
    [src]="imageUrl.value != '' ? imageUrl.value : 'assets/placeholder.png'"
    alt="Upload Product Image">

</mat-card-content>

  <ng-container *ngIf="percentage | async as pct">
    <ng-container *ngIf="snapshot | async as snap">
      <mat-progress-bar
        *ngIf="isActive(snap)"
        color="primary"
        mode="buffer"
        [value]="pct"
        bufferValue="0">
      </mat-progress-bar>
    </ng-container>
  </ng-container>

<mat-card-actions fxLayout="row" fxLayoutAlign="end end">
  <button mat-flat-button type="submit" [disabled]="!productForm.valid || !productForm.dirty" color="accent">Save</button>
</mat-card-actions>

</mat-card>

</form>

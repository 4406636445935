import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductCreateComponent } from './products/product/product-create/product-create.component';
// import { ProductEditComponent } from './products/product/product-edit/product-edit.component';
import { ProductsComponent } from './products/products.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './orders/order/order.component';
import { LocationsComponent } from './locations/locations.component';
import { LocationCreateComponent } from './locations/location-create/location-create.component';
import { AboutComponent } from './about/about.component';
import { AboutHogsbackComponent } from './about-hogsback/about-hogsback.component';
import { ContactComponent } from './contact/contact.component';
import { CartComponent } from './cart/cart.component';
import { AuthGuard } from './auth.guard';

// import { UserComponent } from  './user/user.component';
// import { LoginComponent } from  './user/login/login.component';
// import { RegisterComponent } from  './user/register/register.component';
// import { ForgotPasswordComponent } from  './user/forgot-password/forgot-password.component';
// import { VerifyEmailComponent } from  './user/verify-email/verify-email.component';

const routes: Routes = [
  { path: 'about', component: AboutComponent },
  { path: 'about-hogsback', component: AboutHogsbackComponent },
  { path: 'contact', component: ContactComponent },
  { path: '', redirectTo: 'about', pathMatch: 'full' },
  { path: 'products', component: ProductsComponent },
  { path: 'product-create', component: ProductCreateComponent, canActivate: [AuthGuard] },
  { path: 'product-edit/:id', component: ProductCreateComponent, canActivate: [AuthGuard] },
  { path: 'cart', component: CartComponent },
  { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] },
  { path: 'order/:id', component: OrderComponent, canActivate: [AuthGuard] },
  { path: 'locations', component: LocationsComponent, canActivate: [AuthGuard] },
  { path: 'location-create', component: LocationCreateComponent, canActivate: [AuthGuard] },
  // { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
  // { path: 'login',component:  LoginComponent},
  // { path: 'register', component:  LoginComponent },
  // { path: 'forgot-password', component:  LoginComponent },
  // { path: 'verify-email', component:  LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

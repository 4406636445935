import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Place } from './place';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  getLocations() {
    return this.firestore.collection('locations').snapshotChanges();
  }

  createLocation(place: Place) {
    /*
    const data = {
      name: place.name,
      address: place.address,
      suburb: place.suburb,
      city: place.city,
      mapsUrl: place.mapsUrl,
      notes: place.notes
    };
    */

    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection(`locations`)
        .add(place)
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  updateDeliveryDate(placeId: string, date) {
    this.firestore.doc('locations/' + placeId).update({ nextDeliveryDate: date });
  }

  updateDeliveryTime(placeId: string, time) {
    this.firestore.doc('locations/' + placeId).update({ deliveryTime: time });
  }

  deleteLocation(placeId: string) {
    this.firestore.doc('locations/' + placeId).delete();
  }

  setLocationVisibility(placeId: string, active: boolean) {
    this.firestore.doc('locations/' + placeId).update({ active: active });
  }

  saveNotes(placeId: string, value: string) {
    this.firestore.doc('locations/' + placeId).update({ notes: value });
  }

  saveMinAmount(placeId: string, value: number) {
    this.firestore.doc('locations/' + placeId).update({ min_amount: value });
  }


}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: number): string {
    let format: string;
    let text: string = '';
    const itemDate = new Date(value);
    const nowDate = new Date();

    if (itemDate.toDateString() === nowDate.toDateString()) {
      // text = 'Today @ ';
      format = 'HH:mm';
    } else if (itemDate.getDate() === nowDate.getDate() - 1) {
      text = 'Yesterday @ ';
      format = 'HH:mm';
    } else if (itemDate.getDate() > nowDate.getDate() - 7) {
      format = 'EEEE d @ HH:mm';
    } else if (itemDate.getFullYear() === nowDate.getFullYear()) {
      format = 'EEEE, d MMMM, HH:mm';
    } else {
      format = 'EEEE, d MMMM y, HH:mm';
    }
    return text + this.datePipe.transform(value, format);
  }

}

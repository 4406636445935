import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../product.service';
import { Product } from '../../product/product';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AngularFireStorage, AngularFireUploadTask } from "@angular/fire/storage";
import { finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class ProductCreateComponent implements OnInit {

  productForm: FormGroup;
  productId: string;
  // editing: boolean;
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: Observable<string>;

  constructor(
    private productService: ProductService,
    private storage: AngularFireStorage,
    private location: Location,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.createForm();
    const id = this.route.snapshot.paramMap.get('id');
    id ?
      this.productService.getProduct(id).subscribe(data => {
        // this.editing = true;
        this.productId = id;
        this.productForm.setValue(data.payload.data() as Product);
      }) : '';
    }

  createForm() {
    this.productForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      price: ['', Validators.required],
      imageUrl: ['', Validators.required],
      published: [true]
    });
  }

  onSubmit() {
    if(this.productId) {
      this.productService.updateProduct(this.productId, this.productForm.value);
      this.location.back();
    } else {
      this.productService.createProduct(this.productForm.value)
        .then(
          response => {
            // console.log(response);
            this.location.back();
          },
          error => {
            console.log(error);
          });
    }
  }

  onFileSelected(event) {
      var n = Date.now();
      const file = event.target.files[0];
      const filePath = `products/${n}`;
      const fileRef = this.storage.ref(filePath);
      this.task = this.storage.upload(`products/${n}`, file);
      this.snapshot = this.task.snapshotChanges();
      this.percentage = this.task.percentageChanges();
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.downloadURL = fileRef.getDownloadURL();
            this.downloadURL.subscribe(url => {
              if (url) {
                this.productForm.controls.imageUrl.setValue(url);
                this.productForm.markAsDirty();
            }
            });
          })
        ).subscribe(url => {});
    }

    isActive(snapshot) {
      return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
    }
}

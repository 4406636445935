  <mat-card *ngIf="(order | async) as order">

  <a mat-button (click)="back()" color="accent" style="margin-bottom: 15px">
    <mat-icon>arrow_back</mat-icon>
    <span> Back</span>
  </a>

    <span fxLayout="row">
      <mat-card-title>
        Order #{{ orderID }}
      </mat-card-title>
      <span fxFlex></span>
      <mat-card-subtitle>
        {{ order?.orderTime.toDate() | relativeDate }}
      </mat-card-subtitle>
    </span>

    <mat-card-subtitle fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between start">
      <span><strong>{{ order?.user.name }}</strong></span>
      <span>{{ order?.user.email }}</span>
      <span>{{ order?.user.tel }}</span>
      <span><strong>R {{ order?.orderTotal | number: '1.2-3' }}</strong> ({{ order?.itemCount }})</span>
    </mat-card-subtitle>

    <mat-card-subtitle *ngIf="order.user.comments as comments">
      NOTES: {{ comments }}
    </mat-card-subtitle>

    <mat-card-content>

      <h4 fxHide.lt-sm fxLayoutAlign="space-between start" style="font-weight: bold">
        <product fxFlex="40">Product</product>
        <qty fxFlex="20">QTY</qty>
        <price fxFlex="20">Price</price>
        <amount fxFlex="20">Amount</amount>
      </h4>

      <ng-container *ngFor="let item of order.products">
        <div fxLayout="row" fxLayoutAlign="space-between start" style="padding: 8px 0 8px 0">
          <product fxFlex="40">{{ item.product }}</product>
          <div fxFlex="60" fxLayout="row" fxLayoutAlign="start start">
            <qty fxFlex="33">{{ item.qty }}</qty>
            <price fxFlex="33">{{ item.price | number: '1.2-3' }}</price>
            <amount fxFlex="33">{{ item.amount | number: '1.2-3' }}</amount>
          </div>
        </div>
        <mat-divider></mat-divider>
      </ng-container>

      <h4 fxLayoutAlign="space-between start" style="font-weight: bold">
        <product fxFlex="40">Total</product>
        <qty fxFlex="20">{{ order.itemCount }}</qty>
        <price fxFlex="20"></price>
        <amount fxFlex="20">{{ order.orderTotal | currency: 'ZAR ' }}</amount>
      </h4>

      <mat-card-content *ngIf="order.delivery.place && order.delivery.place == 'Hogsback'">
        <strong style="text-transform: uppercase">Deliver to</strong>: {{ order.user.address }}, {{ order.delivery.place }}
      </mat-card-content>

      <mat-card-content *ngIf="order.user.location == 'custom'">
        <strong style="text-transform: uppercase">Courier to</strong>: {{ order.delivery.address }}
      </mat-card-content>

      <mat-card-content *ngIf="order.user.location != 'custom' && order.delivery.place != 'Hogsback'">
        <strong style="text-transform: uppercase">Deliver to</strong>: {{ order.delivery.place }} on {{ order.delivery.date.toDate() | date: 'fullDate' }} @ {{ order.delivery.time }}
      </mat-card-content>

    </mat-card-content>

  <mat-card-actions fxLayoutGap="8px">

    <button [disabled]="order?.paid" mat-flat-button color="accent" (click)="mark(orderID, 'paid', true)">
      <mat-icon [svgIcon]="order?.paid ? 'check' : 'register'" aria-hidden="false" aria-label="Mark Paid"></mat-icon>
      <span> {{ order?.paid ? 'Paid' : 'Pay' }} </span>
    </button>

    <button [disabled]="order?.packed" mat-flat-button color="accent" (click)="mark(orderID, 'packed', true)">
      <mat-icon [svgIcon]="order?.packed ? 'check' : 'packed'" aria-hidden="false" aria-label="Mark Packed"></mat-icon>
      <span> {{ order?.packed ? 'Packed' : 'Pack' }} </span>
    </button>

    <button [disabled]="order?.delivered" mat-flat-button color="accent" (click)="mark(orderID, 'delivered', true)">
      <mat-icon [svgIcon]="order?.delivered ? 'check' : 'truck'" aria-hidden="false" aria-label="Mark Delivered"></mat-icon>
      <span> {{ order?.delivered ? 'Delivered' : 'Deliver' }} </span>
    </button>

    <span fxFlex></span>

    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu" color="accent">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="mark(orderID, 'paid', order?.paid ? false : true)">
        <mat-icon svgIcon="register"></mat-icon>
        <span> {{ order?.paid ? 'Mark Unpaid' : 'Mark Paid' }}  </span>
      </button>
      <button mat-menu-item (click)="mark(orderID, 'packed', order?.packed ? false : true)">
        <mat-icon svgIcon="packed"></mat-icon>
        <span> {{ order?.packed ? 'Mark Not Packed' : 'Mark Packed' }} </span>
      </button>
      <button mat-menu-item (click)="mark(orderID, 'delivered', order?.delivered ? false : true)">
        <mat-icon svgIcon="truck"></mat-icon>
        <span> {{ order?.delivered ? 'Mark Not Delivered' : 'Mark Delivered' }} </span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item color="warn" (click)="deleteOrder(orderID)">
        <mat-icon>delete</mat-icon>
        <span> Delete </span>
      </button>
    </mat-menu>

  </mat-card-actions>

</mat-card>

<div style="padding: 2%">

  <mat-card class="location-box" *ngFor="let place of places" [ngClass]="{'not-published': !place.active}" style="margin-bottom: 8px;">
    <mat-card-title>{{ place.name }}</mat-card-title>
    <mat-card-subtitle fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between start">
      <span>{{ place.address }}, {{ place.suburb }}, {{ place.city }}</span>
    </mat-card-subtitle>

    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Notes</mat-label>
      <textarea #placefield="ngModel" matInput autocomplete="off" type="text" id="notes" name="notes" [(ngModel)]="place.notes" placeholder="Notes"></textarea>
    </mat-form-field>

    <mat-card-subtitle *ngIf="place.notes as notes">
      NOTES: {{ notes }}
    </mat-card-subtitle>

    <mat-card-content fxLayoutGap="2%">

      <mat-form-field fxFlex="40" appearance="fill">
        <mat-label>Next Delivery Date</mat-label>
        <input matInput
          [matDatepicker]="picker"
          [value]="place.nextDeliveryDate?.toDate()"
          placeholder="Next Delivery"
          (dateChange)="setDeliveryDate(place.id, $event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker color="accent"></mat-datepicker>
      </mat-form-field>

      <!-- formControlName="fromTime" [value]="fromTime.value" (selectionChange)="onTimeChange()" -->
      <mat-form-field appearance="fill" fxFlex="25">
        <mat-label>Time</mat-label>
        <mat-select
          [value]="place.deliveryTime"
          placeholder="Pickup Time"
          (selectionChange)="setDeliveryTime(place.id, $event)">
          <mat-option *ngFor="let time of times" [value]="time">
            {{ time }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" fxFlex="31">
        <mat-label>Min Amount</mat-label>
        <span matPrefix>ZAR &nbsp;</span>
        <input #min_order="ngModel" matInput autocomplete="off" type="number" id="min_order" name="min_order" required [(ngModel)]="place.min_amount" placeholder="140">
        <span matSuffix>.00</span>
      </mat-form-field>

    </mat-card-content>

    <mat-card-actions fxLayoutGap="8px">

      <button mat-icon-button color="warn" (click)="deleteLocation(place.id, place.name)">
        <mat-icon aria-hidden="false" aria-label="Delete Location">delete</mat-icon>
      </button>

      <span fxFlex></span>

      <button mat-icon-button color="accent" [disabled]="placefield.pristine && min_order.pristine" (click)="save(place.id, placefield.value, min_order.value)">
        <mat-icon>save</mat-icon>
      </button>

      <!--
      <button mat-icon-button color="primary" routerLink="/edit">
        <mat-icon>edit</mat-icon>
      </button>
      -->

      <button mat-icon-button color="primary" (click)="setVisibility(place.id, place.active ? false : true)">
        <mat-icon>{{ place.active ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>

    </mat-card-actions>

  </mat-card>

</div>

<!--
<button style="position: fixed; bottom: 25px; left: 50%; margin-left: -24px" mat-fab [matMenuTriggerFor]="menu" aria-label="Order Filter">
  <mat-icon svgIcon="eye"></mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="paid = !paid">
    <span>Paid</span>
  </button>
  <button mat-menu-item (click)="completed = !completed">
    <span>Completed</span>
  </button>
</mat-menu>
-->


<button *ngIf="_isAdmin" routerLink="/location-create" class="bottom-button" mat-fab color="accent" aria-label="Add a Location">
  <mat-icon>add</mat-icon>
</button>

<mat-toolbar color="primary" style="position: fixed; background: rgba(0, 0, 0, 0.5); z-index: 999">
  <button mat-icon-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-xs>
    <mat-icon>menu</mat-icon>
  </button>

  <button fxShow="false" fxShow.lt-sm mat-flat-button color="accent" routerLink="/products">Our Chocolates</button>

  <div fxFlex fxLayout="row" fxShow="false" fxShow.gt-xs fxLayoutGap="8px">
    <button mat-icon-button routerLink="/about">
      <mat-icon>home</mat-icon>
    </button>
    <button mat-flat-button color="accent" routerLink="/products">Our Chocolates</button>
    <!-- <a mat-button href="https://www.hogsback.co.za" target="_blank">About Hogsback</a> -->
    <button mat-button routerLink="/about-hogsback">About Hogsback</button>
    <button mat-button routerLink="/contact">Contact Us</button>

    <span fxFlex></span>
    <button *ngIf="_isLoggedIn && _isAdmin" mat-stroked-button routerLink="/orders">
      <mat-icon svgIcon="basket" color="accent" aria-hidden="false" aria-label="Orders"></mat-icon>
      <span> Orders</span>
    </button>
  </div>

  <span fxFlex></span>

  <a href="https://pay.yoco.com/v/the-chocolate-shop" matTooltip="Buy a Gift Voucher" target="_blank" mat-icon-button>
    <mat-icon svgIcon="gift" style="color: #fcb603" aria-hidden="false" aria-label="Voucher Icon"></mat-icon>
  </a>

  <a href="https://www.facebook.com/The-Chocolate-Shoppe-972465336144348/" target="_blank" mat-icon-button>
    <mat-icon svgIcon="facebook" aria-hidden="false" aria-label="Facebook Icon"></mat-icon>
  </a>

  <!--
  <span fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
    <div class="fb-share-button" data-href="http://www.thechocolateshoppe.co.za" data-layout="button" data-size="large" data-mobile-iframe="true">
      <a class="fb-xfbml-parse-ignore" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fwww.thechocolateshoppe.co.za%2F&amp;src=sdkpreparse">
        Share
      </a>
    </div>
  </span>
  -->

  <button mat-icon-button [matMenuTriggerFor]="userMenu" aria-label="User Account">
    <mat-icon>person</mat-icon>
  </button>
  <mat-menu #userMenu="matMenu">
    <button *ngIf="_isLoggedIn && _isAdmin" mat-menu-item routerLink="/orders">
      <mat-icon svgIcon="basket" color="accent" aria-hidden="false" aria-label="Orders"></mat-icon>
      <span>Orders</span>
    </button>
    <button *ngIf="_isLoggedIn && _isAdmin" mat-menu-item routerLink="/locations">
      <mat-icon svgIcon="basket" color="accent" aria-hidden="false" aria-label="Orders"></mat-icon>
      <span>Locations</span>
    </button>
    <button *ngIf="!_isLoggedIn" mat-menu-item (click)="googleLogin()">
      <mat-icon svgIcon="sign-in" aria-hidden="false" aria-label="Log in"></mat-icon>
      <span>Login</span>
    </button>
    <button *ngIf="_isLoggedIn" mat-menu-item (click)="logout()">
      <mat-icon svgIcon="sign-out" aria-hidden="false" aria-label="Log out"></mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>

</mat-toolbar>

<mat-menu x-position="before" #menu="matMenu">
  <button mat-menu-item routerLink="/about">
    <mat-icon svgIcon="info" aria-hidden="false" aria-label="About Us"></mat-icon>
    <span>About Us</span>
  </button>
  <button mat-menu-item routerLink="/products">
    <mat-icon svgIcon="store" aria-hidden="false" aria-label="Our Chocolates"></mat-icon>
    <span>Our Chocolates</span>
  </button>
  <button mat-menu-item routerLink="/contact">
    <mat-icon svgIcon="phone" aria-hidden="false" aria-label="Contact Us"></mat-icon>
    <span>Contact Us</span>
  </button>
  <a mat-menu-item routerLink="/about-hogsback">
    <mat-icon svgIcon="question" aria-hidden="false" aria-label="About Hogsback"></mat-icon>
    <span>About Hogsback</span>
  </a>
</mat-menu>

<div class="container">
  <router-outlet></router-outlet>
</div>

<!--
<div class="footer">
  Copyright &copy; The Chocolate Shoppe<br />
  Design &amp; Hosting by <a style="color: #FFF" href="http://www.mando.co.za"> Mando</a>
  |
  <div *ngIf="_isLoggedIn else login">
    <a mat-button href (click)="logout()">Logout</a>
  </div>
  <ng-template #login>
    <a mat-button routerLink="/login">Login</a>
  </ng-template>
</div>
-->

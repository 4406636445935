import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '../orders/order';

@Pipe({
  name: 'filterOrder',
  pure: false
})
export class FilterOrderPipe implements PipeTransform {

  transform(orders: Order[], type: string): unknown {
    if (!orders || !type) {
      return orders;
    }

    let filteredOrders: Order[];

    switch (type) {
      case 'inProgress':
        filteredOrders = orders.filter(order => (!order.delivered || !order.paid));
        break;
      case 'completed':
        filteredOrders = orders.filter(order => (order.delivered && order.paid));
        break;
      case 'notDelivered':
        filteredOrders = orders.filter(order => !order.delivered);
        break;
      case 'packed':
        filteredOrders = orders.filter(order => order.packed);
        break;
      case 'unpaid':
        filteredOrders = orders.filter(order => !order.paid);
        break;
      default:
        break;
    }
    return filteredOrders;
  }

}

<div class="padded-container">

  <a href="index.html"><img src="assets/logo-white.png" style="display: block; margin: auto; margin-bottom: 15px; padding-top: 25px;" /></a>

  <h3>Chocolate doesn't ask silly questions... <br />Chocolate understands!</h3>

  <div style="text-align: center">
    <button mat-raised-button color="accent" routerLink="/products">SHOP HERE</button>
  </div>

  <h1 style="text-align: center">Fine Chocolates & Confections</h1>

  <p>
    Set high in the Amathole Mountains of the Eastern Cape with its dramatic views and indigenous forests lies the magical, quaint village of Hogsback where all our chocolates are lovingly handcrafted.
  </p>
  <p>
    We offer a wide selection of these delightful treats and place importance on quality and attention to detail in every piece. We’re always researching and updating our products with new and exciting flavours and combinations so be sure to keep
    watching our website for these products.
  </p>
  <p>
    We only use natural ingredients to flavour our products and we don't add preservatives, so they are best enjoyed within 10 days of purchase. If you'd like to keep them longer, they can be refrigerated or frozen in an airtight container.
  </p>
  <p>
    Looking for chocolates for wedding favours or any other special occasion? Simply email us with your requirements and we will gladly get back to you with a quote.
  </p>
  <p>
    If you’re interested in becoming a stockist of our chocolates, we would love to hear from you.
  </p>
  <p>
    We’re located on the Main Road of Hogsback. You can't miss us.
  </p>

    <table width="100%">
      <tr><th colspan="2" align="left"><h2>Hours of operation</h2></th></tr>
      <tr><td align="left">Monday</td><td align="left">9am - 4pm</td></tr>
      <tr><td align="left">Tuesday</td><td align="left">Closed</td></tr>
      <tr><td align="left">Wednesday - Saturday</td><td align="left">9am - 4pm</td></tr>
      <tr><td align="left">Sunday</td><td align="left">9am - 3pm</td></tr>
    </table>

  <br /><br />

  <div class="banner-image" style="background-image: url(assets/banner01.jpg)"></div>

  <a href="https://www.explorersway.co.za" target="_blank">
    <img src="assets/explorers-logo.png" style = "display: block; margin-left: auto; margin-right: auto">
  </a>
  
</div>

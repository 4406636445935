import { Component, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  contactForm: FormGroup;
  disabledSubmitButton = true;

  @HostListener('input') oninput() {

    if (this.contactForm.valid) {
      this.disabledSubmitButton = false;
    } else {
      this.disabledSubmitButton = true;
    }
  }

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private _location: Location
  ) {
    this.contactForm = fb.group({
      'name': ['', Validators.required],
      'email': ['', Validators.compose([Validators.required, Validators.email])],
      'tel': ['', Validators.required],
      'comments': ['', Validators.required]
    });
  }

  onSubmit() {
    const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = new HttpParams();
    body = body.set('email', this.contactForm.get('email').value);
    body = body.set('name',  this.contactForm.get('name').value);
    body = body.set('tel',  this.contactForm.get('tel').value);
    body = body.set('comments',  this.contactForm.get('comments').value);

    const url = 'https://www.thechocolateshoppe.co.za/the_server_files/send_mail.php';
     this.http.post(url, body, { headers: myheader }).subscribe(
     (res: Response) => {
       // document.getElementById('message').innerHTML = res['sentence'];
       this.contactForm.disable();
       this.disabledSubmitButton = true;
       this.openSnackBar(res['sentence']);
     }
  );
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {
      duration: 5000,
    });

    snackBarRef.afterDismissed().subscribe(() => {
      this._location.back();
    });

  }

}

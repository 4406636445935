<div class="padded-container">

<div fxLayout="row" fxLayoutAlign="space-between center">
  <h1>
    About Hogsback
  </h1>
  <span>More on <a mat-raised-button color="primary" href="https://www.hogsback.co.za" target="_blank">www.hogsback.co.za</a></span>
</div>

<p>High up in the Amatola mountains and surrounded by indigenous forests,  clear waterfalls and swirling myth, the magical, mystical little village of Hogsback soothes the spirit and rejuvenates the soul. The many hiking trails lead the visitor into the pristine beauty of nature, the majestic old yellowwood tree, peace and tranquility.</p>

<p>Hogsback, with its wonderful mountains, spectacular views, mystical forests, swirling mists, beautiful birds, butterflies and mushrooms, and its often glorious sunsets, has an air of enchantment and tranquility all of its own.</p>
<p>Hogsback is the ideal holiday destination for nature lovers and is well known for its beautiful spring, summer and autumn gardens.</p>

<h2>Name</h2>
<p>There are two (or maybe more) theories as how the village received its name. Some think it was named for Captain Hogg, Commander of Fort Michell. Some others think that it is because one of the three peaks that overlook the village, when seen from a certain angle, resembles a Hog's Back (A hog's back is a geological term describing this particular rock formation).</p>

<h2>History</h2>
<p>The earliest known written reference to 'Hogsback' was found in the journal of the painter Thomas Baines, who passed the 'Hogs Back' while on his travels deeper inside South Africa in 1848. Besides the 'Hogsback', the highest peak in the area is the 1 954 m Gaika's Kop.</p>

<p>The nearby Xhosa tribes called the surrounding area Qabimbola (meaning 'red clay on the face') as the surrounding clay was used for face painting. Gaika's Kop was one of the Xhosa strongholds. British troops were later stationed here and a number of forts were built to guard the border with the Xhosa. One of these forts was Fort Michell, named after Colonel Michell of the Warwickshire regiment, who was at one point in charge of troops in the area. The name has since changed to Fort Mitchell. Mitchell's Pass was also named after him. The outline of Fort Michell, and the first road from Fort Hare can still be seen.</p>

<p>The conflict between the British and Xhosa continued further north and a small settlement began to grow. Some of the first inhabitants of Hogsback were Summerton, Odendaal and Booysens. Thomas Summerton, a gardener from Oxford, has a legacy that survives in the local gardens and his attempts to re-create the English countryside. This can be seen in apple orchards, avenues lined with hazelnut, berry fruits and the flowering plants that have spread throughout the surrounding area. European flowers, bushes and trees include rhododendrons, azaleas, fruits and berries. He named his farm Cherrie Orchard and also built a furrow - Summerton's furrow - to carry water from a stream on Tor Doone.</p>

<p>In the 1880s, Mr. Collins built a small hotel, called the Hogsback Hydro, of which later became the Hogsback Inn. Hunterstoun, the private estate of Mr David Hunter, in 1910. Mr. Hunter was the honorary secretary and treasurer of the Lovedale Institution and the Victoria College near Alice. Forestry began in 1883 with a survey to determine the best areas for planting. The Hogsback pass was opened in 1932. Hobbiton-on-Hogsback was started in 1946 as a holiday camp for children who could not afford to go on holidays and with the aid of several organisations and private donations, had grown to an outdoor education facility for underprivileged children. There was also a shop, the Handy Log Cabin, situated in the grounds of Arminel.</p>

<p>At the turn of the century, local farmers and townsfolk started to build holiday homes here and its charms became more widely known. The cottages were mostly of wattle and daub with thatched roofs. A rule was also made in regard to the size of the plots, namely that each one had to be at least two morgen.</p>

<h2>Geography and Tourism</h2>
<p>The area is known for indigenous forests, waterfalls and trout fishing. The large gardens in the surrounding area also include exotic plants from all parts of the Earth, and it is a popular destination and tourist attraction among both International and South African tourists. Apart from 'the Hogsback', the tallest peak in the area is the 1 954 m Gaika's Kop.</p>

<h2>Hiking and Walking Trails</h2>
<p>One of the best ways to experience the stunning beauty of Hogsback is on foot. There are scores of hiking routes and walks that will take you through the forests, up the mountains, past the waterfalls and to the spectacular views. General information on Hogsback such as maps of walks, hiking and mountain bike trails are available for purchase from the Hogsback Tourist Office located near the entrance of the village. These trails vary from short easy walks to trails taking 3–4 hours and longer. The following are some of the well known Hogsback hiking trails and all offer excellent views: The Labyrinth at the Edge, The 39 Steps Falls and Arboretum, Madonna and Child Falls, Kettlespout Falls, The Three Hogs, Tyumy Pools walk, The Three Waterfall Trail, The Bluff Walk, The Military Path, The Sunset loop, The Big Tree and The Art and Craft Trail.</p>

<h2>Climate</h2>
<p>Hogsback has a cool Oceanic Climate, which compares similarly to the climate of England. It has a climate range from as low as 3.5°C in July to as high as 24.9°C in January. Hogsback receives an average annual precipitation of 974mm. Hogsback receives most of its rainfall in summer, often accompanied by thunderstorms.</p>

<h2>Location</h2>
<p>Hogsback is located roughly 30 km from Alice. Overall, it is in the central-southern part of South Africa, in the centre of the Eastern Cape province, and is roughly half way between the town of Alice and the village of Cathcart. Nearby towns include Seymour to the west, Fort Hare and Alice to the south, Cathcart, Whittlesea and the Waterdown Dam to the north and Keiskammahoek to the east. However, travel east and west of the town is rare because there is little infrastructure and ridges/mountains to these sides.</p>

<h2>Tolkien Connection</h2>
<p>The Amatola Forest in the Hogsback area is often claimed as J. R. R. Tolkien's (who was born in South Africa) inspiration for The Lord of the Rings, in particular for his fictional forest of Mirkwood. The link between Tolkien and Hogsback would seem to be a back formation from the establishment in 1947 of Hobbiton on Hogsback, a holiday and educational camp for underprivileged children. This was ten years after the publication of The Hobbit which was a huge success and which would have inspired the camp's founders as they set up in such an evocatively forested area.</p>

<p style="font-style:italic; color: #FFF">References: <a href="http://en.wikipedia.org/wiki/Hogsback,_Eastern_Cape">www.wikipedia.org</a>

</div>

<div class="flex-container">

<!--
  <ng-container *ngIf="!_isAdmin">
    <mat-card class="info-box" fxFlex="100" style="margin: 8px; margin-bottom: 15px;">
        <mat-card-title style="font-size: 16px; line-height: 22px">
          We currently only deliver to the following locations ...with more coming soon!
        </mat-card-title>

          <mat-chip-list>
            <mat-chip *ngFor="let place of uniquePlaces; let i = index" selected color="accent">
              {{ place }}
            </mat-chip>
          </mat-chip-list>
          <br />
          -->
          <!--
          <span style="font-size: 18px" *ngFor="let place of uniquePlaces; let i = index">
            {{ place }},
          </span>
          -->
          <!--
      <mat-card-subtitle><i>You will be asked to select your collection point on the <strong>Checkout</strong> page.</i></mat-card-subtitle>
    </mat-card>
  </ng-container>
  -->

  <mat-card class="product-box" *ngFor="let product of products | orderBy : 'name'" [ngClass]="{'not-published': !product.published && _isAdmin}" [style.background-image]="'url(' + product?.imageUrl + ')' | safeUrl: 'style'">

    <mat-icon class="disabled" *ngIf="!product.published && _isAdmin">visibility_off</mat-icon>
    <div *ngIf="!product.published && !_isAdmin" class="out-of-stock">Out of stock</div>

    <mat-card-header fxFlex>
      <mat-card-title>
        {{ product.name}}
      </mat-card-title>
    </mat-card-header>

    <div *ngIf="product.description.length > 1" class="angle-badge" [title]="product.description">{{ product.description }}</div>

      <mat-card-actions *ngIf="product.published && !_isAdmin" fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
          <button [disabled]="!getBasketCount(product.id)" mat-icon-button color="accent" (click)="removeFromBasket(product.id)">
            <mat-icon>remove_circle</mat-icon>
          </button>
            <span class="price" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px" [matBadge]="getBasketCount(product.id)" matBadgeColor="accent" matBadgeOverlap="true" matBadgePosition="above after">
              <mat-icon aria-hidden="false" aria-label="Shopping Cart">
                shopping_basket
              </mat-icon>
              <span>R {{ product.price }}</span>
            </span>
          <button mat-icon-button color="accent" (click)="addToBasket(product.id)">
            <mat-icon>add_circle</mat-icon>
          </button>
      </mat-card-actions>

    <mat-card-actions *ngIf="_isAdmin" fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
      <button mat-icon-button color="primary" [routerLink]="['/product-edit', product.id]">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button color="warn" (click)="delete(product.name, product.id)">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="setVisibility(product.id, product.published ? false : true)">
        <mat-icon>{{ product.published ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
    </mat-card-actions>

  </mat-card>

</div>

<button *ngIf="_isAdmin" routerLink="/product-create" class="bottom-button" mat-fab color="accent" aria-label="Add a Product">
  <mat-icon>add</mat-icon>
</button>

<mat-toolbar *ngIf="cartOrders()" color="primary" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="8px" style="font-weight: normal; background: rgba(0, 0, 0, 0.8); z-index: 800; position: fixed; left: 0px; bottom: 0px">
  <button mat-button color="accent" (click)="clearCart()">
    Clear
  </button>
  <span fxFlex></span>
  <span>{{ itemsTotal | currency: 'R ' }}</span>
  <button routerLink="/cart" mat-flat-button color="accent" [matBadge]="itemsCount" matBadgeColor="primary" matBadgeOverlap="true" matBadgePosition="above after">
    <mat-icon aria-hidden="false" aria-label="Shopping Cart">
      shopping_basket
    </mat-icon>
    <span> Checkout</span>
  </button>
</mat-toolbar>

<ng-container *ngIf="orders$; let orders; else loading">

  <mat-card *ngIf="orders.length === 0">
    No results, try clearing filters
  </mat-card>

  <mat-tab-group color="accent" backgroundColor="primary">

    <mat-tab label="In Progress">

      <ng-template matTabContent>
        <ng-container *ngFor="let order of orders">

      <mat-card *ngIf="!order.paid || !order.packed || !order.delivered" style="margin-bottom: 8px;">

          <span fxLayout="row">
            <mat-card-title>
              <a [routerLink]="['/order', order?.id]">Order #{{ order?.id }}</a>
            </mat-card-title>
            <span fxFlex></span>
            <mat-card-subtitle>
              {{ order.orderTime?.toDate() | relativeDate }}
            </mat-card-subtitle>
          </span>

          <mat-card-subtitle fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between start">
            <span><strong>{{ order.user.name }}</strong></span>
            <span>{{ order.user.email }}</span>
            <span>{{ order.user.tel }}</span>
            <span><strong>R {{ order.orderTotal | number: '1.2-3' }}</strong> ({{ order.itemCount }})</span>
          </mat-card-subtitle>

        <mat-card-actions fxLayoutGap="8px">

          <button [disabled]="order.paid" mat-flat-button color="accent" (click)="mark(order?.id, 'paid', true)">
            <mat-icon [svgIcon]="order.paid ? 'check' : 'register'" aria-hidden="false" aria-label="Mark Paid"></mat-icon>
            <span> {{ order.paid ? 'Paid' : 'Pay' }} </span>
          </button>

          <button [disabled]="order.packed" mat-flat-button color="accent" (click)="mark(order?.id, 'packed', true)">
            <mat-icon [svgIcon]="order.packed ? 'check' : 'packed'" aria-hidden="false" aria-label="Mark Packed"></mat-icon>
            <span> {{ order.packed ? 'Packed' : 'Pack' }} </span>
          </button>

          <button [disabled]="order.delivered" mat-flat-button color="accent" (click)="mark(order?.id, 'delivered', true)">
            <mat-icon [svgIcon]="order.delivered ? 'check' : 'truck'" aria-hidden="false" aria-label="Mark Delivered"></mat-icon>
            <span> {{ order.delivered ? 'Delivered' : 'Deliver' }} </span>
          </button>

          <span fxFlex></span>

          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu" color="accent">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="mark(order?.id, 'paid', order.paid ? false : true)">
              <mat-icon svgIcon="register"></mat-icon>
              <span> {{ order.paid ? 'Mark Unpaid' : 'Mark Paid' }}  </span>
            </button>
            <button mat-menu-item (click)="mark(order?.id, 'packed', order.packed ? false : true)">
              <mat-icon svgIcon="packed"></mat-icon>
              <span> {{ order.packed ? 'Mark Not Packed' : 'Mark Packed' }} </span>
            </button>
            <button mat-menu-item (click)="mark(order?.id, 'delivered', order.delivered ? false : true)">
              <mat-icon svgIcon="truck"></mat-icon>
              <span> {{ order.delivered ? 'Mark Not Delivered' : 'Mark Delivered' }} </span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item color="warn" (click)="deleteOrder(order?.id)">
              <mat-icon>delete</mat-icon>
              <span> Delete </span>
            </button>
          </mat-menu>

        </mat-card-actions>

      </mat-card>
      </ng-container>
    </ng-template>

    </mat-tab>

    <mat-tab label="Completed">
      <ng-template matTabContent>
        <ng-container *ngFor="let order of orders">

        <mat-card *ngIf="order.paid && order.packed && order.delivered" style="margin-bottom: 8px;">

            <span fxLayout="row">
              <mat-card-title>
                Order #{{ order?.id }}
              </mat-card-title>
              <span fxFlex></span>
              <mat-card-subtitle>
                {{ order.orderTime?.toDate() | relativeDate }}
              </mat-card-subtitle>
            </span>

            <mat-card-subtitle fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between start">
              <span><strong>{{ order.user.name }}</strong></span>
              <span>{{ order.user.email }}</span>
              <span>{{ order.user.tel }}</span>
              <span><strong>R {{ order.orderTotal | number: '1.2-3' }}</strong> ({{ order.itemCount }})</span>
            </mat-card-subtitle>

          <mat-card-actions fxLayoutGap="8px">

            <button [disabled]="order.paid" mat-flat-button color="accent" (click)="mark(order?.id, 'paid', true)">
              <mat-icon [svgIcon]="order.paid ? 'check' : 'register'" aria-hidden="false" aria-label="Mark Paid"></mat-icon>
              <span> {{ order.paid ? 'Paid' : 'Pay' }} </span>
            </button>

            <button [disabled]="order.packed" mat-flat-button color="accent" (click)="mark(order?.id, 'packed', true)">
              <mat-icon [svgIcon]="order.packed ? 'check' : 'packed'" aria-hidden="false" aria-label="Mark Packed"></mat-icon>
              <span> {{ order.packed ? 'Packed' : 'Pack' }} </span>
            </button>

            <button [disabled]="order.delivered" mat-flat-button color="accent" (click)="mark(order?.id, 'delivered', true)">
              <mat-icon [svgIcon]="order.delivered ? 'check' : 'truck'" aria-hidden="false" aria-label="Mark Delivered"></mat-icon>
              <span> {{ order.delivered ? 'Delivered' : 'Deliver' }} </span>
            </button>

            <span fxFlex></span>

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu" color="accent">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="mark(order?.id, 'paid', order.paid ? false : true)">
                <mat-icon svgIcon="register"></mat-icon>
                <span> {{ order.paid ? 'Mark Unpaid' : 'Mark Paid' }}  </span>
              </button>
              <button mat-menu-item (click)="mark(order?.id, 'packed', order.packed ? false : true)">
                <mat-icon svgIcon="packed"></mat-icon>
                <span> {{ order.packed ? 'Mark Not Packed' : 'Mark Packed' }} </span>
              </button>
              <button mat-menu-item (click)="mark(order?.id, 'delivered', order.delivered ? false : true)">
                <mat-icon svgIcon="truck"></mat-icon>
                <span> {{ order.delivered ? 'Mark Not Delivered' : 'Mark Delivered' }} </span>
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item color="warn" (click)="deleteOrder(order?.id)">
                <mat-icon>delete</mat-icon>
                <span> Delete </span>
              </button>
            </mat-menu>

          </mat-card-actions>

        </mat-card>
      </ng-container>

      </ng-template>
    </mat-tab>

  </mat-tab-group>

</ng-container>

<ng-template #loading>
  <mat-card>Loading&hellip;</mat-card>
</ng-template>

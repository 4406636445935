import { Injectable, Output, EventEmitter } from '@angular/core';

import { Router } from "@angular/router";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from 'firebase';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: User;
  user$: Observable<any>;

  @Output() fireSession: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public router: Router
  ) {

    const allowed_users = ['mornayv@gmail.com', 'thechocolateshop99@gmail.com'];

    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        // Logged in
        if (allowed_users.includes(user.email)) {
          return this.firestore.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          this.router.navigate(['/about']);
          return of(null);
        }
      })
    )

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
        if(allowed_users.includes(user.email)) {
          this.fireSession.emit({ isLoggedIn: true, isAdmin: true });
        } else {
          this.fireSession.emit({ isLoggedIn: true, isAdmin: false });
        }
      } else {
        this.fireSession.emit({ isLoggedIn: false, isAdmin: false });
        localStorage.setItem('user', null);
      }
    })

  }

  getEmitter() {
    return this.fireSession;
  }

  async login(email: string, password: string) {
    // var result = await this.afAuth.signInWithEmailAndPassword(email, password);
    var result = await this.afAuth.signInWithEmailAndPassword(email, password).catch(err => {
      alert(err);
    }).then(
      data => this.router.navigate(['/products'])
    );
    // this.fireSession.emit({ isLoggedIn: true, isAdmin: false });
  }

  get isLoggedIn(): boolean {
      const user = JSON.parse(localStorage.getItem('user'));
      return user !== null;
  }

  async register(email: string, password: string) {
    var result = await this.afAuth.createUserWithEmailAndPassword(email, password)
    this.sendEmailVerification();
  }

  async sendEmailVerification() {
    await (await this.afAuth.currentUser).sendEmailVerification()
    this.router.navigate(['/verify-email']);
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  async loginWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    this.updateUserData(credential.user);
    return this.router.navigate(['/orders']);
  }

  private updateUserData(user: User) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument = this.firestore.doc(`users/${user.uid}`);

    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
    }
    return userRef.set(data, { merge: true });

  }

  async signOut() {
    await this.afAuth.signOut();
    this.fireSession.emit({ isLoggedIn: false, isAdmin: false });
    localStorage.removeItem('user');
    this.router.navigate(['/']);
  }

  async logout() {
    await this.afAuth.signOut();
    this.fireSession.emit({ isLoggedIn: false, isAdmin: false });
    localStorage.removeItem('user');
    this.router.navigate(['/']);
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Product } from './product/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private firestore: AngularFirestore
  ) { }

  getProducts() {
    return this.firestore.collection('products').snapshotChanges();
  }

  getProduct(id: string) {
    return this.firestore.collection('products/').doc(id).snapshotChanges();
  }

  createProduct(product: Product) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection('products')
        .add(product)
        .then(
          res => resolve(res),
          err => reject(err)
        );
    });
  }

  updateProduct(productId: string, product: Product) {
    this.firestore.doc('products/' + productId).update(product);
  }

  deleteProduct(productId: string) {
    this.firestore.doc('products/' + productId).delete();
  }

  setProductVisibility(productId: string, visibility: boolean) {
    this.firestore.doc('products/' + productId).update({ published: visibility });
  }

}

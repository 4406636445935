import { User } from './../user/user';
import { Product } from './../products/product/product';

export class Order {
  id?: string;
  orderID: string;
  orderTime: any;
  paid: boolean = false;
  paymentDetails: Object;
  packed: boolean = false;
  delivered: boolean = false;
  user: User;
  products: Product;
  delivery: any;
  itemCount: number;
  orderTotal: number;
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductsComponent } from './products/products.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';

import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { ProductCreateComponent } from './products/product/product-create/product-create.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { CartComponent } from './cart/cart.component';
import { OrderbyPipe } from './pipes/orderby.pipe';
import { OrdersComponent } from './orders/orders.component';
import { AboutHogsbackComponent } from './about-hogsback/about-hogsback.component';
import { LocationsComponent } from './locations/locations.component';
import { LocationCreateComponent } from './locations/location-create/location-create.component';
import { FilterOrderPipe } from './pipes/filter-order.pipe';
import { CallbackPipe } from './pipes/callback.pipe';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { OrderComponent } from './orders/order/order.component';
import { AboutChocolatesComponent } from './about-chocolates/about-chocolates.component';

@NgModule({
  declarations: [
    AppComponent,
    ProductsComponent,
    ProductCreateComponent,
    AboutComponent,
    ContactComponent,
    SafeUrlPipe,
    CartComponent,
    OrderbyPipe,
    OrdersComponent,
    AboutHogsbackComponent,
    LocationsComponent,
    LocationCreateComponent,
    FilterOrderPipe,
    CallbackPipe,
    RelativeDatePipe,
    OrderComponent,
    AboutChocolatesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'mytestapp'),
    AngularFirestoreModule, // Only required for database features
    AngularFireAuthModule, // Only required for auth features,
    AngularFireStorageModule, // Only required for storage features
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatTableModule,
    MatSnackBarModule,
    MatChipsModule,
    MatTabsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-ZA' },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

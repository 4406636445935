<form [formGroup]="orderForm">
  <mat-card *ngIf="dataSource.length > 0; else noProducts">
    <mat-card-title>Place Order</mat-card-title>
    <mat-card-subtitle>Please provide us with some details and review your order below.</mat-card-subtitle>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
      <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
        <mat-label>Name</mat-label>
        <input matInput type="text" placeholder="Jane Doe" formControlName="name">
        <mat-icon matSuffix>person</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
        <mat-label>Phone Number</mat-label>
        <input matInput type="tel" placeholder="080 123 1234" formControlName="tel">
        <mat-icon matSuffix>phone</mat-icon>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
      <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
        <mat-label>Email Address</mat-label>
        <input matInput type="email" placeholder="itsme@gmail.com" formControlName="email">
        <mat-icon matSuffix>email</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="example-full-width" floatLabel="always">
        <mat-label>Choose your preferred delivery location</mat-label>
        <mat-select placeholder="Delivery Location" formControlName="location" #location (selectionChange)="setDelivery(location)">
          <ng-container *ngFor="let place of places">
            <mat-option *ngIf="place.active" [value]="place.id">
              {{ place.city + ' | ' }}
              {{ place.name }}
              {{ place.suburb ? ', ' + place.suburb : '' }}
            </mat-option>
          </ng-container>
          <mat-option value="custom">Deliver to my door</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
<!-- *ngIf="place.nextDeliveryDate && (place.nextDeliveryDate?.toDate() > today)" -->
      <ng-container *ngIf="place">
        <div *ngIf="(place.nextDeliveryDate?.toDate() > today) && place.deliveryTime; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          <div class="info">
            Next Delivery to {{ place.name }}, {{ place.suburb }}, {{ place.city }} is
            <strong>{{ place?.nextDeliveryDate.toDate() | date : 'fullDate'}} @ {{ place?.deliveryTime }}</strong>
          </div>
        </ng-template>
        <ng-template #elseBlock>
          <div class="info">
            We will let you know via email when we will be delivering to <strong>{{ place.name }}, {{ place.suburb }}, {{ place.city }}</strong>
          </div>
        </ng-template>
      </ng-container>

      <div *ngIf="grandTotal < place?.min_amount" class="info warn" style="width: 100%" fxLayout="row" fxLayoutAlign="space-between center">
        <div>The minimum order amount for delivery to {{ place.city }} is <strong>R {{ place?.min_amount | number: '1.2-3' }}</strong>.</div>
        <button mat-flat-button color="accent" routerLink="/products">Shop</button>
      </div>

      <ng-container *ngIf="location.value == 'custom'">
        <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
          <mat-label>Where would you like us to deliver to?</mat-label>
          <input matInput type="text" placeholder="Your full address" formControlName="deliveryaddress">
          <mat-icon matSuffix>place</mat-icon>
        </mat-form-field>

        <!--
        <div class="info" style="width: 100%" fxLayout="row" fxLayoutAlign="space-between center">
          <span fxFlex>
            <strong>A flat shipping fee of R120 will be added to your order.</strong><br>
          </span>
          <button mat-flat-button color="accent" routerLink="/products">Shop</button>
        </div>
        -->

      </ng-container>

    <mat-form-field appearance="outline" floatLabel="always" class="example-full-width">
      <mat-label>Anything you'd like to add?</mat-label>
      <textarea matInput formControlName="comments" rows="2" placeholder="Your chocolates rock! ;)"></textarea>
    </mat-form-field>

    <mat-card-title>Items in your Basket</mat-card-title>
    <mat-card-subtitle>You can also go back to products if you wish to add some more items.</mat-card-subtitle>

    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="product">
        <mat-header-cell *matHeaderCellDef> Product </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.product }} </mat-cell>
        <mat-footer-cell *matFooterCellDef> Total </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="qty">
        <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center"> QTY </mat-header-cell>
        <mat-cell *matCellDef="let element" fxLayoutAlign="center center">{{ element.qty }}</mat-cell>
        <mat-footer-cell *matFooterCellDef fxLayoutAlign="center center">{{ totalItems }}</mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef fxLayoutAlign="end center"> Price </mat-header-cell>
        <mat-cell *matCellDef="let element" fxLayoutAlign="end center"> {{ element.price | number: '1.2-3' }} </mat-cell>
        <mat-footer-cell *matFooterCellDef fxLayoutAlign="end center"></mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef fxLayoutAlign="end center"> Amount ZAR </mat-header-cell>
        <mat-cell *matCellDef="let element" fxLayoutAlign="end center"> {{ element.amount | number: '1.2-3' }} </mat-cell>
        <mat-footer-cell *matFooterCellDef fxLayoutAlign="end center">{{ grandTotal | number: '1.2-3' }}</mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
    </mat-table>

    <mat-card-actions fxLayoutAlign="space-between end">
      <button mat-button color="primary" routerLink="/products">Back to Shop</button>
      <span>

        <!--
        <button id="checkout-button" mat-flat-button color="accent" [disabled]="!orderForm.valid || (grandTotal < place?.min_amount)" (click)="payYoco()">Pay by Card</button>
        -->
        <button id="eft-button" mat-flat-button color="accent" [disabled]="!orderForm.valid || (grandTotal < place?.min_amount)" (click)="payEFT()">Pay by EFT</button>
      </span>
    </mat-card-actions>

  </mat-card>

  <ng-template #noProducts>
    <mat-card>
      <mat-card-title>
        Please add some products to your cart first!
      </mat-card-title>
      <mat-card-actions fxLayoutAlign="space-between end">
        <span fxFlex></span>
        <button mat-flat-button color="accent" routerLink="/products">Back to Shop</button>
      </mat-card-actions>
    </mat-card>
  </ng-template>

  <div id="my-overlay" class="wrapper">
    <div class="overlay">
      <mat-spinner id="my-spinner" color="accent"></mat-spinner>
      <mat-card id="response_card">
        <mat-card-title id="response_title">{{ responseErrorTitle }}</mat-card-title>
        <mat-card-subtitle id="response_subtitle">{{ responseErrorSubTitle }}</mat-card-subtitle>
        <mat-card-content id="response_content">{{ responseErrorContent }}</mat-card-content>
        <mat-card-actions id="response_actions">
          <button mat-flat-button color="accent" (click)="hideOverlay()">Ok</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

</form>
